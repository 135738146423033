<template>
  <div>
    <Switcher v-model="computedRequested" :disabled="switcherDisabled" labelPosition="left" class="mx-12 relative">
      <span class="text-white-1 text-xl font-bold">{{ $t('setup.microphone.title') }}</span>

      <Spinner
        v-if="switcherDisabled || isWaitingForSpeakerStream"
        size="small"
        class="absolute left-full top-0 bottom-0 my-auto"
      />
    </Switcher>

    <Select
      v-model="computedId"
      :options="computedDevices"
      :placeholder="$t('setup.microphone.select_placeholder')"
      valueKey="deviceId"
      labelKey="label"
      class="z-2 mt-6"
    />

    <transition name="slide" mode="out-in">
      <p v-if="!computedRequested" class="pt-10">
        {{ $t('setup.not_connected') }}
      </p>

      <div v-else class="pt-10">
        <MicrophonePreview
          class="text-center relative z-0"
          :stream="speakerStream"
          :errors="microphoneErrors"
          :loading="isWaitingForSpeakerStream"
        />

        <MicrophoneTest class="mt-10" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import Switcher from '@/components/Switcher.vue';
import Select from '@/components/Select.vue';
import MicrophonePreview from '@/components/MicrophonePreview.vue';
import MicrophoneTest from '@/components/MicrophoneTest.vue';
import Spinner from '@/components/Spinner.vue';

import Logger from '@/modules/logger';

export default {
  name: 'SetupMicrophone',
  components: { Switcher, Select, MicrophonePreview, MicrophoneTest, Spinner },
  computed: {
    ...mapState([
      'devices',
      'speakerStream',
      'microphoneErrors',
      'microphoneEnabled',
      'microphoneRequested',
      'microphoneId',
      'isWaitingForSpeakerStream',
    ]),
    computedRequested: {
      get() {
        return this.microphoneRequested;
      },
      set(value) {
        // eslint-disable-next-line no-console
        console.log(`SetupMicrophone: set microphoneRequested = ${value}`);
        Logger.log(`SetupMicrophone: set microphoneRequested = ${value}`);
        this.setKey({ key: 'microphoneRequested', microphoneRequested: value });
      },
    },
    computedId: {
      get() {
        return this.microphoneId;
      },
      set(value) {
        // eslint-disable-next-line no-console
        console.log(`SetupMicrophone: set microphoneId = ${value}`);
        Logger.log(`SetupMicrophone: set microphoneId = ${value}`);
        this.setKey({ key: 'microphoneId', microphoneId: value });
      },
    },
    switcherDisabled() {
      return this.microphoneEnabled !== this.computedRequested;
    },
    computedDevices() {
      return this.devices.filter((device) => device.kind === `audioinput`);
    },
  },
  methods: {
    ...mapMutations(['setKey']),
  },
};
</script>
