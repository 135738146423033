<template>
  <div>
    <Switcher v-model="computedRequested" :disabled="switcherDisabled" labelPosition="left" class="mx-12 relative">
      <span class="text-white-1 text-xl font-bold">{{ $t('setup.camera.title') }}</span>

      <Spinner
        v-if="switcherDisabled || isWaitingForSpeakerStream"
        size="small"
        class="absolute left-full top-0 bottom-0 my-auto"
      />
    </Switcher>

    <Select
      v-model="computedId"
      :options="computedDevices"
      :placeholder="$t('setup.camera.select_placeholder')"
      valueKey="deviceId"
      labelKey="label"
      class="relative z-2 mt-6"
    />

    <SpeakerStream #default="{ stream, enabled }">
      <transition name="slide" mode="out-in">
        <p v-if="!enabled" class="pt-10">{{ $t('setup.not_connected') }}</p>

        <div v-else class="pt-10">
          <MediaPreview
            class="z-1"
            :stream="stream"
            :enabled="enabled"
            :errors="computedErrors"
            icon="SpeakerSVG"
            iconOverVideo
          />

          <p v-if="cameraErrors.length === 0" class="text-white-1 text-center font-medium mt-2">
            {{ captionText }}
          </p>
        </div>
      </transition>
    </SpeakerStream>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import Switcher from '@/components/Switcher.vue';
import Select from '@/components/Select.vue';
import SpeakerStream from '@/components/SpeakerStream.vue';
import MediaPreview from '@/components/MediaPreview.vue';
import Spinner from '@/components/Spinner.vue';

import Logger from '@/modules/logger';

export default {
  name: 'SetupCamera',
  components: { Switcher, Select, SpeakerStream, MediaPreview, Spinner },
  computed: {
    ...mapState([
      'cameraErrors',
      'cameraRequested',
      'cameraEnabled',
      'cameraId',
      'isWaitingForSpeakerStream',
      'devices',
    ]),
    computedRequested: {
      get() {
        return this.cameraRequested;
      },
      set(value) {
        // eslint-disable-next-line no-console
        console.log(`SetupMicrophone: set cameraRequested = ${value}`);
        Logger.log(`SetupMicrophone: set cameraRequested = ${value}`);
        this.setKey({ key: 'cameraRequested', cameraRequested: value });
      },
    },
    computedId: {
      get() {
        return this.cameraId;
      },
      set(value) {
        // eslint-disable-next-line no-console
        console.log(`SetupMicrophone: set cameraId = ${value}`);
        Logger.log(`SetupMicrophone: set cameraId = ${value}`);
        this.setKey({ key: 'cameraId', cameraId: value });
      },
    },
    switcherDisabled() {
      return this.cameraEnabled !== this.computedRequested;
    },
    computedErrors() {
      if (this.isWaitingForSpeakerStream) {
        return [];
      }

      return this.cameraErrors;
    },
    computedDevices() {
      return this.devices.filter((device) => device.kind === `videoinput`);
    },
    captionText() {
      const captions = this.$i18n.t('setup.camera.captions');

      return captions[Math.floor(Math.random() * captions.length)];
    },
  },
  methods: {
    ...mapMutations(['setKey']),
  },
};
</script>
