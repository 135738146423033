<template>
  <div class="relative z-0">
    <slot :stream="speakerStream" :enabled="cameraEnabled" :open="open" :close="closeCurrentStream"></slot>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import Logger from '@/modules/logger';

export default {
  name: 'SpeakerStream',
  data() {
    return {
      audioSkipWatch: false,
      videoSkipWatch: false,
      openTriggeredAt: null,
    };
  },
  computed: {
    ...mapState([
      'cameraId',
      'microphoneId',
      'speakerStream',
      'cameraRequested',
      'cameraEnabled',
      'microphoneRequested',
      'microphoneEnabled',
      'devices',
      'supportEmail',
    ]),
  },
  methods: {
    ...mapMutations(['setKey', 'addNotification']),
    buildConstraintsVariants() {
      const updateDeviceIdsInConstraints = (constraints) => {
        if (!this.microphoneRequested) {
          constraints.audio = false;
        } else if (this.microphoneId && this.microphoneId !== '') {
          constraints.audio = { deviceId: this.microphoneId };
        }

        if (!this.cameraRequested) {
          constraints.video = false;
        } else if (this.cameraId && this.cameraId !== '') {
          constraints.video.deviceId = this.cameraId;
        }

        return constraints;
      };

      const variants = [];

      variants.push(
        updateDeviceIdsInConstraints({
          audio: true,
          video: {
            aspectRatio: {
              ideal: 16 / 9.0,
              min: 4 / 3.0,
            },
            frameRate: {
              ideal: 25,
              max: 30,
            },
            width: {
              max: 1920,
              ideal: 1280,
              min: 320,
            },
            height: {
              max: 1080,
              ideal: 720,
              min: 240,
            },
          },
        }),
      );

      variants.push(updateDeviceIdsInConstraints({ audio: true, video: {} }));
      variants.push({
        audio: this.microphoneRequested,
        video: this.cameraRequested
          ? {
              aspectRatio: {
                ideal: 16 / 9.0,
                min: 4 / 3.0,
              },
              frameRate: {
                ideal: 25,
                max: 30,
              },
              width: {
                max: 1920,
                ideal: 1280,
                min: 320,
              },
              height: {
                max: 1080,
                ideal: 720,
                min: 240,
              },
            }
          : false,
      });
      variants.push({ audio: this.microphoneRequested, video: this.cameraRequested });

      return variants;
    },
    open() {
      const now = window.performance.now();
      this.openTriggeredAt = now;

      if (!this.cameraRequested && !this.microphoneRequested) {
        this.updateStream(null);
        return;
      }

      this.setKey({ key: 'isWaitingForSpeakerStream', isWaitingForSpeakerStream: true });

      this.clearError('camera');
      this.clearError('microphone');

      const constraintsVariants = this.buildConstraintsVariants();
      let lastFailedConstraintIndex = -1;

      const openStream = (constraintsVariantIndex) => {
        if (now !== this.openTriggeredAt) {
          return;
        }

        const constraints = constraintsVariants[constraintsVariantIndex];

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            if (now !== this.openTriggeredAt) {
              return null;
            }

            const cameraRecognized =
              !this.cameraRequested || this.devices.some((d) => d.kind === 'videoinput' && d.deviceId !== '');
            const microphoneRecognized =
              !this.microphoneRequested || this.devices.some((d) => d.kind === 'audioinput' && d.deviceId !== '');

            if (cameraRecognized && microphoneRecognized) {
              return stream;
            }

            if (lastFailedConstraintIndex !== constraintsVariantIndex) {
              lastFailedConstraintIndex = constraintsVariantIndex;

              return this.updateDevices().then(() => {
                openStream(constraintsVariantIndex);
                return null;
              });
            }

            if (!cameraRecognized) {
              this.addNotification({
                type: 'error',
                code: 'no_camera',
                closeable: true,
              });
            }

            if (!microphoneRecognized) {
              this.addNotification({
                type: 'error',
                code: 'no_microphone',
                closeable: true,
              });
            }

            return null;
          })
          .then((stream) => {
            if (!stream) {
              return;
            }

            const streamDevices = this.getStreamDevices(stream);

            const videoOk =
              !!constraints.video === !!streamDevices.video &&
              (!constraints.video || constraints.video === true || constraints.video.deviceId === streamDevices.video);

            const audioOk =
              !!constraints.audio === !!streamDevices.audio &&
              (!constraints.audio || constraints.audio === true || constraints.audio.deviceId === streamDevices.audio);

            if (!videoOk || !audioOk) {
              console.warn(
                `SpeakerStream: getUserMedia(${constraintsVariantIndex + 1}/${
                  constraintsVariants.length
                }): constraints not met, constraints = ${JSON.stringify(
                  constraints,
                )}, stream devices = ${JSON.stringify(streamDevices)}`,
              );
              Logger.log(
                `SpeakerStream: getUserMedia(${constraintsVariantIndex + 1}/${
                  constraintsVariants.length
                }): constraints not met, constraints = ${JSON.stringify(
                  constraints,
                )}, stream devices = ${JSON.stringify(streamDevices)}`,
              );

              if (constraintsVariantIndex < constraintsVariants.length - 1) {
                setTimeout(() => openStream(constraintsVariantIndex + 1), 25);
                return;
              }

              const error = new Error('Media constraints not met and there are no more constraints to try.');
              error.name = 'SlidesLiveRecorderOutOfConstraints';
              throw error;
            }

            this.updateStream(stream);
            this.setKey({ key: 'isWaitingForSpeakerStream', isWaitingForSpeakerStream: false });
          })
          .catch((error) => {
            if (now !== this.openTriggeredAt) {
              return;
            }

            if (error.name !== 'SlidesLiveRecorderOutOfConstraints' && error.name !== 'NotAllowedError') {
              // eslint-disable-next-line no-console
              console.warn(
                `SpeakerStream: getUserMedia(${constraintsVariantIndex + 1}/${constraintsVariants.length}): error = ${
                  error.name
                } (${error.message}), constraints = ${JSON.stringify(constraints)}`,
              );
              Logger.log(
                `SpeakerStream: getUserMedia(${constraintsVariantIndex + 1}/${constraintsVariants.length}): error = ${
                  error.name
                } (${error.message}), constraints = ${JSON.stringify(constraints)}`,
              );

              if (constraintsVariantIndex < constraintsVariants.length - 1) {
                setTimeout(() => openStream(constraintsVariantIndex + 1), 25);
                return;
              }
            }

            this.setKey({ key: 'isRecording', isRecording: false });
            this.setKey({ key: 'isWaitingForStreams', isWaitingForStreams: false });

            const cameraWorking =
              error.name !== 'SlidesLiveRecorderOutOfConstraints' && this.cameraRequested === this.cameraEnabled;
            const microphoneWorking =
              error.name !== 'SlidesLiveRecorderOutOfConstraints' &&
              this.microphoneRequested === this.microphoneEnabled;

            if (!cameraWorking) {
              this.setKey({ key: 'cameraRequested', cameraRequested: false });
              this.setKey({ key: 'cameraEnabled', cameraEnabled: false });
            }

            if (!microphoneWorking) {
              this.setKey({ key: 'microphoneRequested', microphoneRequested: false });
              this.setKey({ key: 'microphoneEnabled', microphoneEnabled: false });
            }

            if (!cameraWorking && !microphoneWorking) {
              this.updateStream(null);
            }

            this.setKey({ key: 'isWaitingForSpeakerStream', isWaitingForSpeakerStream: false });

            if (error.name === 'NotAllowedError') {
              if (!cameraWorking) {
                let content = this.$i18n.t('errors.camera_declined');
                content += '<br />';
                content += this.$i18n.t('errors.how_to_enable_camera_microphone');

                this.addNotification({
                  type: 'warning',
                  content,
                  code: 'camera_declined',
                  closeable: true,
                  closeAfterS: 8,
                });
              }

              if (!microphoneWorking) {
                let content = this.$i18n.t('errors.microphone_declined');
                content += '<br />';
                content += this.$i18n.t('errors.how_to_enable_camera_microphone');

                this.addNotification({
                  type: 'warning',
                  content,
                  code: 'microphone_declined',
                  closeable: true,
                  closeAfterS: 8,
                });
              }
            } else if (error.name === 'SlidesLiveRecorderOutOfConstraints') {
              let content = this.$i18n.t('errors.media_constraints_not_met');
              content += '<br />';
              content += this.$i18n.t('errors.contact_support_if_issue_persists', { email: this.supportEmail });

              this.addNotification({
                type: 'warning',
                content,
                code: 'media_constraints_not_met',
                closeable: true,
                closeAfterS: 8,
              });
            } else {
              let content = this.$i18n.t('errors.unknown_speaker_stream_error');
              content += '<br />';
              content += this.$i18n.t('errors.contact_support_if_issue_persists', { email: this.supportEmail });
              content += '<br />';
              content += `${error.name}: ${error.message}`;

              this.addNotification({
                type: 'warning',
                content,
                code: 'unknown_speaker_stream_error',
                closeable: true,
                closeAfterS: 8,
              });
            }
          });
      };

      if (this.devices.length > 0) {
        openStream(0);
      } else {
        this.updateDevices().then(() => {
          openStream(0);
        });
      }
    },
    closeCurrentStream() {
      if (this.speakerStream) {
        for (const track of this.speakerStream.getTracks()) {
          track.stop();
        }
      }

      this.setKey({ key: 'speakerStream', speakerStream: null });
    },
    updateStream(stream = null) {
      this.closeCurrentStream();

      const tracks = this.checkTracksFromStream(stream);
      const hasVideoTrack = !!tracks.video;
      const hasAudioTrack = !!tracks.audio;

      this.setKey({ key: 'cameraRequested', cameraRequested: hasVideoTrack });
      this.setKey({ key: 'cameraEnabled', cameraEnabled: hasVideoTrack });

      this.setKey({ key: 'microphoneRequested', microphoneRequested: hasAudioTrack });
      this.setKey({ key: 'microphoneEnabled', microphoneEnabled: hasAudioTrack });

      this.setKey({ key: 'speakerStream', speakerStream: stream });

      // eslint-disable-next-line no-console
      console.log(`SpeakerStream: has video track = ${hasVideoTrack}, has audio track = ${hasAudioTrack}`);
      Logger.log(`SpeakerStream: has video track = ${hasVideoTrack}, has audio track = ${hasAudioTrack}`);

      this.updateErrors(tracks);
      this.addAudioTrackListeners(tracks.audio);
    },
    checkTracksFromStream(stream) {
      const result = {
        audio: undefined,
        video: undefined,
      };

      if (!stream) {
        return result;
      }

      for (const track of stream.getTracks()) {
        const trackSettings = track.getSettings();
        const storeKey = `${track.kind === 'video' ? 'camera' : 'microphone'}Id`;

        result[track.kind] = track;

        if (this[storeKey] !== trackSettings.deviceId) {
          // eslint-disable-next-line no-console
          console.log(`SpeakerStream: update ${track.kind} device = ${trackSettings.deviceId}`);
          Logger.log(`SpeakerStream: update ${track.kind} device = ${trackSettings.deviceId}`);

          const skipKey = `${track.kind}SkipWatch`;

          this[skipKey] = true;
          this.setKey({ key: storeKey, [storeKey]: trackSettings.deviceId });
        } else {
          // eslint-disable-next-line no-console
          console.log(`SpeakerStream: keep ${track.kind} device = ${trackSettings.deviceId}`);
          Logger.log(`SpeakerStream: keep ${track.kind} device = ${trackSettings.deviceId}`);
        }
      }

      return result;
    },
    getStreamDevices(stream) {
      const result = {
        audio: undefined,
        video: undefined,
      };

      if (!stream) {
        return result;
      }

      for (const track of stream.getTracks()) {
        const trackSettings = track.getSettings();
        result[track.kind] = trackSettings.deviceId;
      }

      return result;
    },
    updateErrors(tracks) {
      if (!this.cameraEnabled) {
        this.setError('camera', 'camera_disabled');
      } else if (!this.cameraId || !tracks.video) {
        this.setError('camera', 'no_camera');
      } else {
        this.clearError('camera');
      }

      if (!this.microphoneEnabled) {
        this.setError('microphone', 'microphone_disabled');
      } else if (!this.microphoneId || !tracks.audio) {
        this.setError('microphone', 'no_microphone');
      } else if (tracks.audio && tracks.audio.muted) {
        this.setError('microphone', 'microphone_muted');
      } else {
        this.clearError('microphone');
      }
    },
    clearError(kind) {
      const storeKey = `${kind}Errors`;

      this.setKey({ key: storeKey, [storeKey]: [] });
    },
    setError(kind, key) {
      console.warn(`SpeakerStream: Error: ${kind}, ${key}`);
      Logger.log(`SpeakerStream: Error: ${kind}, ${key}`);

      const storeKey = `${kind}Errors`;
      const errors = [key];

      this.setKey({ key: storeKey, [storeKey]: errors });
    },
    addAudioTrackListeners(track) {
      if (!track) {
        return;
      }

      track.onmute = () => {
        this.setError('audio', 'muted_audio');
      };

      track.onunmute = () => {
        this.clearError('audio');
      };
    },
    updateDevices() {
      return navigator.mediaDevices.enumerateDevices().then((devices) => {
        Logger.log(`SpeakerStream: Update available devices: ${JSON.stringify(devices)}`);

        const cameraStillConnected = devices.some((d) => d.deviceId === this.cameraId);
        const microphoneStillConnected = devices.some((d) => d.deviceId === this.microphoneId);

        if (!cameraStillConnected || !microphoneStillConnected) {
          if (!cameraStillConnected) {
            // eslint-disable-next-line no-console
            console.log('SpeakerStream: selected camera disconnected, using the default');
            Logger.log('SpeakerStream: selected camera disconnected, using the default');

            const cameras = devices.filter((d) => d.kind === 'videoinput');
            const camera = cameras.find((d) => d.deviceId === 'default') || cameras[0];

            this.videoSkipWatch = true;
            if (camera && camera.deviceId !== '') {
              this.setKey({ key: 'cameraId', cameraId: camera.deviceId });
            } else {
              // eslint-disable-next-line no-console
              console.log('SpeakerStream: no default camera');
              Logger.log('SpeakerStream: no default camera');

              this.setKey({ key: 'cameraId', cameraId: '' });
            }
          }

          if (!microphoneStillConnected) {
            // eslint-disable-next-line no-console
            console.log('SpeakerStream: selected microphone disconnected, using the default');
            Logger.log('SpeakerStream: selected microphone disconnected, using the default');

            const microphones = devices.filter((d) => d.kind === 'audioinput');
            const microphone = microphones.find((d) => d.deviceId === 'default') || microphones[0];

            this.audioSkipWatch = true;
            if (microphone && microphone.deviceId !== '') {
              this.setKey({ key: 'microphoneId', microphoneId: microphone.deviceId });
            } else {
              // eslint-disable-next-line no-console
              console.log('SpeakerStream: no default microphone');
              Logger.log('SpeakerStream: no default microphone');

              this.setKey({ key: 'microphoneId', microphoneId: '' });
            }
          }

          this.open();
        }

        this.setKey({ key: 'devices', devices });
      });
    },
  },
  watch: {
    cameraRequested() {
      this.open();
    },
    microphoneRequested() {
      this.open();
    },
    microphoneId() {
      if (this.audioSkipWatch) {
        this.audioSkipWatch = false;
        return;
      }

      this.open();
    },
    cameraId() {
      if (this.videoSkipWatch) {
        this.videoSkipWatch = false;
        return;
      }

      this.open();
    },
  },
  mounted() {
    navigator.mediaDevices.ondevicechange = this.updateDevices;

    if (!this.speakerStream && (this.cameraRequested || this.microphoneRequested)) {
      this.open();
    }
  },
  beforeDestroy() {
    this.closeCurrentStream();
    navigator.mediaDevices.ondevicechange = null;
  },
};
</script>
