import requestFileSystemDirectory from './request_file_system_directory';
import toSnakeCase from './to_snake_case';

function findVideoDuration(fileUrl, fallbackDurationMs, callbacks) {
  return new Promise((resolve) => {
    const video = document.createElement('video');
    video.preload = 'metadata';

    video.onloadedmetadata = () => {
      if (Number.isFinite(video.duration)) {
        resolve(video.duration);
      } else {
        video.ontimeupdate = () => {
          video.ontimeupdate = () => {};
          resolve(video.duration);
        };

        video.currentTime = 1e101;
      }
    };

    video.onerror = () => {
      callbacks.warn(
        `Error while preloading metadata for video, data: ${JSON.stringify({
          video,
          error: video.error,
        })}`,
      );

      resolve(null);
    };

    video.src = fileUrl;
  }).then((duration) => {
    if (duration === null) {
      return { durationMs: fallbackDurationMs, fallback: true };
    }

    if (!Number.isFinite(duration)) {
      return { durationMs: fallbackDurationMs, fallback: true };
    }

    if (duration === 0) {
      return { durationMs: fallbackDurationMs, fallback: true };
    }

    return { durationMs: duration * 1000, fallback: false };
  });
}

export default function (
  token,
  speakerId,
  startedRecording,
  webmSeekableMetadataFixer,
  recordedFileEntry,
  callbacks,
  rewriteFile = false,
) {
  callbacks.log('Starting recording finish.');

  const fallbackDurationMs = startedRecording.stoppedAt - startedRecording.startedAt;

  return new Promise((resolve) => {
    const resolveWithError = (error, errorKey) => {
      callbacks.warn(error);
      resolve({ kind: startedRecording.kind, error: errorKey });
    };

    const resolveWithFile = (fileEntry, file, { webmFixSuccessful }) => {
      const fileUrl = fileEntry.toURL(file.type);

      findVideoDuration(fileUrl, fallbackDurationMs, callbacks).then(({ durationMs, fallback }) => {
        const finishedRecording = {
          kind: startedRecording.kind,
          audio: startedRecording.audio,
          video: startedRecording.video,
          mimeType: startedRecording.mimeType,
          startedAt: startedRecording.startedAt,
          stoppedAt: startedRecording.stoppedAt,
          fixedAt: startedRecording.fixedAt,
          bytesWritten: startedRecording.bytesWritten,
          expectedBytesWritten: startedRecording.expectedBytesWritten,
          needsWebmFix: startedRecording.needsWebmFix,
          durationMs: fallback ? 0 : durationMs,
          durationFallback: fallback ? durationMs : null,
          fileName: file.name,
          fileSize: file.size,
          fileUrl,
          webmFixSuccessful,
        };

        if (startedRecording.displaySurface) {
          finishedRecording.displaySurface = startedRecording.displaySurface;
        }

        resolve(finishedRecording);
      });
    };

    const useRecordedFileAsFinal = () => {
      callbacks.log('Using recorded file as final.');

      recordedFileEntry.file(
        (file) => resolveWithFile(recordedFileEntry, file, { webmFixSuccessful: false }),
        (error) =>
          resolveWithError(
            `Unable to get recorded File. Error: ${JSON.stringify(error)}`,
            `recorder_${toSnakeCase(startedRecording.kind)}_unable_to_get_tmp_file`,
          ),
      );
    };

    if (startedRecording.mimeType && !startedRecording.mimeType.startsWith('video/webm')) {
      callbacks.log(
        // eslint-disable-next-line max-len
        `Skipping WebM data fixing. Recorded file is not a WebM. It is ${startedRecording.mimeType}.`,
      );

      useRecordedFileAsFinal();
      return;
    }

    if (!webmSeekableMetadataFixer) {
      callbacks.log('Skipping WebM data fixing. WebM seekable metadata fixed is not set.');

      useRecordedFileAsFinal();
      return;
    }

    if (startedRecording.recordingFileName === startedRecording.fileName) {
      callbacks.log('Skipping WebM data fixing. Recording file name and file name are equal.');

      useRecordedFileAsFinal();
      return;
    }

    callbacks.log('Fixing WebM data.');

    requestFileSystemDirectory(token, speakerId)
      .then((dirEntry) => {
        dirEntry.getFile(
          startedRecording.fileName,
          { create: true, exclusive: !rewriteFile },
          (fileEntry) => {
            webmSeekableMetadataFixer
              .fixMetadata(recordedFileEntry, fileEntry)
              .then(() => {
                fileEntry.file(
                  (file) => {
                    if (file.size === 0) {
                      callbacks.warn('Fixed file has 0 B, using recorded file.');
                      useRecordedFileAsFinal();
                      return;
                    }

                    callbacks.log('Removing recorded file.', true);

                    recordedFileEntry.remove(
                      () => {
                        callbacks.log('Removed recorded file.', true);
                        resolveWithFile(fileEntry, file, { webmFixSuccessful: true });
                      },
                      (error) => {
                        callbacks.warn(`Removing recorded file failed. Error: ${JSON.stringify(error)}`);
                        resolveWithFile(fileEntry, file, { webmFixSuccessful: true });
                      },
                    );
                  },
                  (error) => {
                    callbacks.warn(`Unable to get fixed File, using recorded file. Error: ${JSON.stringify(error)}`);
                    useRecordedFileAsFinal();
                  },
                );
              })
              .catch((error) => {
                callbacks.warn(`Unable to fix WebM metadata, using recorded file. Error: ${JSON.stringify(error)}`);
                useRecordedFileAsFinal();
              });
          },
          (error) => {
            callbacks.warn(`Unable to get final FileEntry. Error: ${JSON.stringify(error)}`);
            useRecordedFileAsFinal();
          },
        );
      })
      .catch((error) => {
        callbacks.warn(`Unable to get main directory. Error: ${JSON.stringify(error)}`);
        useRecordedFileAsFinal();
      });
  });
}
