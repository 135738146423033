import deleteEntries from './delete_entries';

export default function (token) {
  return new Promise((resolve, reject) => {
    window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;

    window.requestFileSystem(
      window.PERSISTENT,
      0,
      (fs) => {
        fs.root.getDirectory(
          token,
          {},
          (dirEntry) => {
            deleteEntries(dirEntry)
              .then(() => {
                dirEntry.remove(
                  () => {
                    console.warn(`Removed ${dirEntry.fullPath}`);
                    resolve(token);
                  },
                  (error) => console.warn('Removing error', error),
                );
              })
              .catch((error) => reject(error));
          },
          (error) => reject(error),
        );
      },
      (error) => reject(error),
    );
  });
}
