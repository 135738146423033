<template>
  <div>
    <slot :stream="screenSharingStream" :enabled="screenSharingEnabled" :open="open" :close="closeCurrentStream"></slot>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Logger from '@/modules/logger';

import ErrorNotificationsMixin from '@/mixins/error_notifications';

import { upsertJSONFile } from '@/helpers';

export default {
  name: 'DisplayStream',
  mixins: [ErrorNotificationsMixin],
  computed: {
    ...mapState([
      'screenSharingStream',
      'screenSharingRequested',
      'screenSharingEnabled',
      'isRecording',
      'recordingStartRequested',
    ]),
    constraints() {
      const constraints = {
        audio: true,
        video: {
          cursor: 'never',
        },
      };

      const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
      if (supportedConstraints.displaySurface) {
        constraints.video.displaySurface = 'monitor';
      }

      if (!this.screenSharingRequested) {
        constraints.audio = false;
        constraints.video = false;
      }

      return constraints;
    },
  },
  methods: {
    ...mapMutations(['setKey', 'addNotification', 'deleteNotification']),
    ...mapActions(['triggerStopRecording', 'triggerCancelRecordingCountdown']),
    open() {
      this.setKey({
        key: 'isWaitingForScreenSharingStream',
        isWaitingForScreenSharingStream: true,
      });
      this.clearError();
      this.deleteNotification({ code: 'screen_sharing_stopped' });

      const constraints = this.constraints;

      navigator.mediaDevices
        .getDisplayMedia(constraints)
        .then((stream) => this.updateStream(stream))
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.warn(
            `ScreenSharingStream: getDisplayMedia(): error = ${error.name} (${
              error.message
            }), constraints = ${JSON.stringify(constraints)}`,
          );
          Logger.log(
            `ScreenSharingStream: getDisplayMedia(): error = ${error.name} (${
              error.message
            }), constraints = ${JSON.stringify(constraints)}`,
          );

          this.addNotification({
            type: 'warning',
            content: this.$i18n.t('errors.screen_sharing_declined'),
            code: 'screen_sharing_declined',
            closeable: true,
            closeAfterS: 3,
          });

          this.updateStream(null, error);

          this.setKey({ key: 'isWaitingForStreams', isWaitingForStreams: false });
          this.setKey({ key: 'isRecording', isRecording: false });
          this.setKey({
            key: 'screenSharingEnabled',
            screenSharingEnabled: false,
          });
          this.setKey({
            key: 'screenSharingRequested',
            screenSharingRequested: false,
          });
        })
        .finally(() => {
          this.setKey({
            key: 'isWaitingForScreenSharingStream',
            isWaitingForScreenSharingStream: false,
          });
        });
    },
    closeCurrentStream(skipStoppedError = false) {
      if (this.screenSharingStream) {
        this.screenSharingStream.skipStoppedError = skipStoppedError;

        for (const track of this.screenSharingStream.getTracks()) {
          track.stop();
        }
      }

      this.setKey({ key: 'screenSharingStream', screenSharingStream: null });
    },
    updateStream(stream = null, error = null) {
      this.closeCurrentStream(true);

      this.setKey({
        key: 'screenSharingEnabled',
        screenSharingEnabled: this.screenSharingRequested,
      });

      this.updateErrors(error);

      if (!stream) {
        return;
      }

      const videoTracks = stream.getVideoTracks();
      let isEntireScreen = false;

      if (videoTracks.length) {
        const settings = videoTracks[0].getSettings();
        isEntireScreen = settings.displaySurface === 'monitor';

        if (isEntireScreen) {
          this.deleteNotification({ code: 'screen_sharing_not_entire_screen' });
        } else {
          this.addNotification({
            type: 'warning',
            content: this.$i18n.t('errors.screen_sharing_not_entire_screen'),
            closeable: true,
            code: 'screen_sharing_not_entire_screen',
            uniq: true,
          });
        }
      }

      this.setKey({ key: 'screenSharingStream', screenSharingStream: stream });

      // eslint-disable-next-line no-console
      console.log(`ScreenSharingStream: videoTracks = ${videoTracks.length}, entire screen = ${isEntireScreen}`);
      Logger.log(`ScreenSharingStream: videoTracks = ${videoTracks.length}, entire screen = ${isEntireScreen}`);

      stream.oninactive = (event) => {
        if (this.screenSharingStream !== event.target) {
          return;
        }

        console.warn(`ScreenSharingStream: inactive`);
        Logger.log(`ScreenSharingStream: inactive`);

        if (!this.screenSharingStream.skipStoppedError) {
          this.updateStream(null, { name: 'Stopped' });
        } else if (this.screenSharingStream) {
          this.screenSharingStream.skipStoppedError = false;
        }

        if (!this.isRecording && !this.recordingStartRequested) {
          return;
        }

        this.addNotification({
          type: 'warning',
          content: this.$i18n.t('errors.screen_sharing_stopped'),
          code: 'screen_sharing_stopped',
          closeable: true,
          sound: 'error',
          synthesisMessage: this.$i18n.t('errors.stopped_recorder'),
        });

        if (!this.isRecording) {
          this.triggerCancelRecordingCountdown();
          return;
        }

        this.triggerStopRecording()
          .then((version) => {
            upsertJSONFile(version);
          })
          .catch((errors) => {
            errors.forEach((err) => {
              this.notificationFromError([err, 'recorder_reload_and_try_again']);
            });
          });
      };
    },
    updateErrors(error) {
      if (error) {
        this.setError(error);
      } else if (!this.screenSharingEnabled) {
        this.setError({ name: 'Disabled' });
      } else {
        this.clearError();
      }
    },
    clearError() {
      this.setKey({ key: 'screenSharingErrors', screenSharingErrors: [] });
    },
    setError(error) {
      const errors = [];

      console.warn(`ScreenSharingStream: error = ${error.name} (${error.message})`);
      Logger.log(`ScreenSharingStream: error = ${error.name} (${error.message})`);

      if (error && error.name === 'Disabled') {
        errors.push('screen_disabled');
      } else if (error && error.name === 'NotAllowedError' && error.message.indexOf('system') >= 0) {
        errors.push('screen_not_recorded');
        errors.push('screen_capture_disabled_os');
      } else if (error && error.name === 'Stopped') {
        errors.push('screen_not_recorded');
        errors.push('screen_capture_stopped');
      } else {
        errors.push('screen_not_recorded');
        errors.push('screen_capture_disabled');
      }

      this.setKey({ key: 'screenSharingErrors', screenSharingErrors: errors });
    },
  },
  watch: {
    screenSharingRequested(value) {
      if (value) {
        this.clearError();

        if (!this.screenSharingStream) {
          this.open();
        }
      } else {
        this.closeCurrentStream(true);

        this.setKey({
          key: 'screenSharingEnabled',
          screenSharingEnabled: this.screenSharingRequested,
        });

        this.setError({ name: 'Disabled' });
      }
    },
  },
  mounted() {
    if (!this.screenSharingStream && this.screenSharingRequested) {
      this.open();
    } else if (!this.screenSharingRequested) {
      this.setError({ name: 'Disabled' });
    }
  },
};
</script>
